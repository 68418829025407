import React from 'react'
import { Link } from 'gatsby'
import { css } from 'linaria'
import { colors } from '../../utils/theme'
import cx from '../../utils/cx'

const button = css`
  color: ${colors.accent.purple};
  text-decoration: none;
  display: inline-block;
`

const SecondaryButton = ({ children, className, ...rest }) => {
  let classes = cx({
    [button]: true,
    [className]: Boolean(className)
  })

  return (
    <Link {...rest} className={classes}>
      {children}
    </Link>
  )
}
export default SecondaryButton

import React from 'react'
import { css } from 'linaria'
import { colors } from '../../../utils/theme'
import rhythm from '../../../utils/rhythm'
import breakpoints from '../../../utils/tokens/breakpoints'
import cx from '../../../utils/cx'

const lead = css`
  color: ${colors.text.lightGray};
  line-height: 1.8;

  @media (min-width: ${breakpoints.md}) {
    font-size: 1.25rem;
  }
`

const marginTopClass = css`
  margin-top: ${rhythm(1)};

  @media (min-width: ${breakpoints.md}) {
    margin-top: ${rhythm(3 / 2)};
  }
`

const accentColor = css`
  color: ${colors.accent.purple};
`

const Lead = ({ children, className, noMargin = false, accent = false }) => {
  let classes = cx({
    [lead]: true,
    [accentColor]: accent,
    [marginTopClass]: !noMargin,
    [className]: Boolean(className)
  })

  return <p className={classes}>{children}</p>
}

export default Lead

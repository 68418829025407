import React from 'react'
import { css } from 'linaria'
import breakpoints from '../../../utils/tokens/breakpoints'
import rhythm from '../../../utils/rhythm'
import { transition, colors } from '../../../utils/theme'
import hexToRgb from '../../../utils/hexToRgb'

const content = css`
  font-size: 1rem;
  line-height: 1.7;
  margin: 0 auto;
  letter-spacing: 0.0075rem;

  @media (min-width: ${breakpoints.md}) {
    font-size: 1.125rem;

    p {
      margin-bottom: ${rhythm(1)};
    }
  }

  a:not([class]) {
    color: ${colors.accent.purple};
    text-decoration: none;
    border-bottom: 1px solid rgba(${hexToRgb(colors.accent.purple)}, 0.4);
    transition: all ${transition.speed.fast} ${transition.curve.scaleUp};

    &:hover {
      border-bottom-color: rgba(${hexToRgb(colors.accent.purple)}, 1);
    }
  }
`

const Content = ({ children, ...rest }) => (
  <div className={content} {...rest}>
    {children}
  </div>
)

export default Content

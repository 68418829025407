import React from 'react'
import { css } from 'linaria'
import breakpoints from '../../utils/tokens/breakpoints'

const page = css`
  margin-left: auto;
  margin-right: auto;
  padding-left: 8vw;
  padding-right: 8vw;

  @media (min-width: ${breakpoints.md}) {
    padding-left: 4vw;
    padding-right: 4vw;
    overflow: hidden;
  }

  @media (min-width: ${breakpoints.xxl}) {
    padding-left: 0;
    padding-right: 0;
    max-width: 87.5rem;
    overflow: visible;
  }
`

const Main = ({ children }) => <main className={page}>{children}</main>

export default Main

const lightTheme = {
  bg: '#fff',
  'bg-primary': '#0a0012',
  'bg-secondary': '#f5f9fb',
  'bg-almost-primary': '#ffffffbf',
  'bg-hover': '#fff',
  'bg-shadow': '#f5f9fb',
  'bg-light-bar': 'rgba(217, 231, 239, 0.25)',
  'bg-shadow-heavy': '#3c485673',
  'text-primary': '#3c4856',
  'text-secondary': '#3c4856c2',
  'text-contrast': '#fff',
  'text-solid': '#000',
  'text-gradient-from': '#e3eef4',
  'text-gradient-to': 'rgba(227,238,244,.1)',
  'border-light': '#3b485617'
}

const darkTheme = {
  bg: '#100818',
  'bg-primary': '#100818',
  // 'bg-secondary': 'rgba(255, 255, 255, 0.06)',
  'bg-secondary': '#1d1626',
  'bg-almost-primary': '#100818bf',
  'bg-hover': '#221a29',
  'bg-shadow': '#000',
  'bg-light-bar': 'rgba(255, 255, 255, 0.06)',
  'bg-shadow-heavy': '#000',
  'text-primary': '#fff',
  'text-secondary': 'rgba(255, 255, 255, 0.65)',
  'text-contrast': '#000',
  'text-solid': '#fff',
  'text-gradient-from': 'rgba(10, 0, 17, 0.53)',
  'text-gradient-to': 'rgba(255,255,255,0)',
  'border-light': '#1d1626'
}

function themeToVars(theme) {
  return Object.keys(theme).reduce(
    (acc, curr, i) => `${acc} --color-${curr}: ${theme[curr]};`,
    ''
  )
}

export const globals = css`
  :global() {
    @font-face {
      font-family: 'Recoleta';
      font-style: normal;
      font-display: swap;
      font-weight: bold;
      src: url('/fonts/recoleta/subset-Recoleta-SemiBold.woff2') format('woff2'),
        url('/fonts/recoleta/subset-Recoleta-SemiBold.woff') format('woff');
    }
    /* poppins-400normal - latin */
    @font-face {
      font-family: 'Poppins';
      font-style: normal;
      font-display: swap;
      font-weight: 400;
      src: url('/fonts/poppins/poppins-latin-500.woff2') format('woff2'),
        url('/fonts/poppins/poppins-latin-500.woff') format('woff');
    }

    html {
      background: var(--color-bg);
    }

    body {
      background: var(--color-bg);
      color: var(--color-text-primary);

      @media (prefers-color-scheme: light) {
        ${themeToVars(lightTheme)};
      }

      @media (prefers-color-scheme: dark) {
        ${themeToVars(darkTheme)};
      }

      &.dark {
        ${themeToVars(darkTheme)};
      }

      &.light {
        ${themeToVars(lightTheme)};
      }
    }
  }
`

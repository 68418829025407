import React from 'react'
import { css } from 'linaria'
import { colors } from '../../../utils/theme'
import breakpoints from '../../../utils/tokens/breakpoints'

const header = css`
  @media (max-width: ${breakpoints.md}) {
    font-size: calc((10px + 2vh) + 3vw);
  }

  @media (min-width: ${breakpoints.md}) {
    font-size: 2.75rem;
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: 3rem;
  }

  @media (min-width: ${breakpoints.xxl}) {
    font-size: 3.375rem;
  }

  mark {
    color: ${colors.accent.purple};
    background: transparent;
  }
`

const HeroHeading = ({ children }) => <h1 className={header}>{children}</h1>

export default HeroHeading

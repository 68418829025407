import React from 'react'
import { css } from 'linaria'
import breakpoints from '../../utils/tokens/breakpoints'
import rhythm from '../../utils/rhythm'
import cx from '../../utils/cx'

const content = css`
  --rg: ${rhythm(3)};
  --cg: ${rhythm(3 / 2)};
  --mw: ${rhythm(38)};
  --cc: 2;

  display: grid;
  row-gap: var(--rg);
  column-gap: var(--cg);
  max-width: var(--mw);

  align-items: flex-start;
  margin: ${rhythm(1)} auto 0;
  counter-reset: benefits;

  @media (min-width: ${breakpoints.sm}) {
    grid-template-columns: repeat(var(--cc), 1fr);
    margin-top: ${rhythm(2)};
  }

  @media (min-width: ${breakpoints.md}) {
    margin-top: ${rhythm(3)};
  }
`

const wideClass = css`
  column-gap: ${rhythm(2)};
  max-width: 100%;
  max-width: ${rhythm(46.5)};
`

const oddContent = css`
  --cg: calc(6vw + ${rhythm(2)});
  grid-template-columns: repeat(
    auto-fill,
    [col-start] minmax(16rem, 4fr) [col-end]
  );

  @media (min-width: ${breakpoints.md}) {
    > div:nth-child(2) {
      margin-top: ${rhythm(10)};
    }
    > div:nth-child(odd):not(:first-child) {
      margin-top: -${rhythm(4)};
    }
    > div:nth-child(even):not(:nth-child(2)) {
      margin-top: ${rhythm(6)};
    }
  }

  @media (min-width: ${breakpoints.xl}) {
    > div:nth-child(2) {
      margin-top: ${rhythm(15)};
    }
    > div:nth-child(odd):not(:first-child) {
      margin-top: -${rhythm(6)};
    }
    > div:nth-child(even):not(:nth-child(2)) {
      margin-top: ${rhythm(8)};
    }
  }
`

const Content = ({
  className,
  children,
  wide = false,
  oddList = false,
  rowGap,
  columnGap,
  columns,
  maxWidth
}) => {
  let classes = cx({
    [content]: true,
    [oddContent]: oddList,
    [wideClass]: wide,
    [className]: className && className.length > 0
  })

  let styles = {
    ...(rowGap && {
      '--rg': isNaN(rowGap) ? rowGap : rhythm(rowGap)
    }),
    ...(columnGap && {
      '--cg': isNaN(columnGap) ? columnGap : rhythm(columnGap)
    }),
    ...(columns && {
      '--cc': columns
    }),
    ...(maxWidth && {
      '--mw': isNaN(maxWidth) ? maxWidth : rhythm(maxWidth)
    })
  }

  return (
    <div className={classes} style={styles}>
      {children}
    </div>
  )
}

export default Content

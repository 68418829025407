import React, { useContext } from 'react'
import { css } from 'linaria'
import { colors, transition } from '../../utils/theme'
import ThemeContext from '../ThemeContext'

const switcherForm = css`
  margin-top: 20px;
  border: 0;

  fieldset {
    border: 0;
  }

  .theme-switch__input,
  .theme-switch__label {
    position: absolute;
    z-index: 1;
  }

  .theme-switch__input {
    opacity: 0;

    &:hover,
    &:focus {
      + .theme-switch__label {
        ${'' /* background-color: #fff; */}
      }

      + .theme-switch__label span::after {
        ${'' /* background-color: rgba(0, 0, 0, 0.1); */}
      }
    }
  }
`

const input = css`
  position: absolute;
  z-index: 1;
  opacity: 0;

  &:hover,
  &:focus {
    + .theme-switch__label {
      ${'' /* background-color: #fff; */}
    }

    + .theme-switch__label span::after {
      ${'' /* background-color: rgba(0, 0, 0, 0.1); */}
    }
  }

  @media (prefers-color-scheme: dark) {
    body:not(.light) & {
      &:checked ~ label {
        a:first-child {
          opacity: 0.3;
        }

        a:last-child,
        a:hover {
          opacity: 1;
        }

        span::after {
          transform: translate3d(20px, 0, 0);
        }
      }
    }
  }

  &:checked ~ label {
    a:first-child {
      opacity: 0.3;
    }

    a:last-child {
      opacity: 1;
    }

    span::after {
      transform: translate3d(20px, 0, 0);
    }

    a:hover {
      opacity: 1;
    }
  }
`

const label = css`
  position: absolute;
  z-index: 1;
  padding: 15px;
  margin: 0 28px;
  transition: background-color 200ms ease-in-out;
  width: 50px;
  height: 30px;
  border-radius: 50px;
  text-align: center;
  background-color: ${colors.accent.purple};
  box-shadow: -4px 4px 15px inset rgba(0, 0, 0, 0.4);
`

const sunAndMoon = `
  font-size: 2rem;
  position: absolute;
  transform: translate3d(0, -50%, 0);
  top: 50%;
  color: #fff;
  transition: opacity ${transition.speed.fast} ${transition.curve.linear};
  text-decoration: none;

  &:hover {
    opacity: 1;
  }
`

const sun = css`
  ${sunAndMoon};
  right: 100%;
  margin-top: -3px;
  margin-right: 10px;
  font-size: 28px;
`

const moon = css`
  ${sunAndMoon};
  left: 100%;
  margin-left: 10px;
  font-size: 19px;
  opacity: 0.3;
`

const dot = css`
  position: absolute;
  bottom: calc(100% + 10px);
  left: 0;
  width: 100%;

  &::after {
    position: absolute;
    top: calc(100% + 15px);
    left: 5px;
    width: 20px;
    height: 20px;
    content: '';
    border-radius: 50%;
    background-color: #fff;
    transition: transform 200ms, background-color 200ms;
    box-shadow: -3px 3px 8px rgba(0, 0, 0, 0.4);
  }
`

const ThemeSwitcher = () => {
  const { mode, setMode, toggleMode } = useContext(ThemeContext)

  return (
    <fieldset
      aria-label="theme switcher"
      role="radiogroup"
      className={switcherForm}
    >
      <input
        type="checkbox"
        id="themeSwitch"
        name="theme-switch"
        className={input}
        checked={mode === 'dark'}
        onChange={e => toggleMode()}
      />
      <label for="themeSwitch" className={label}>
        <a
          className={sun}
          href="?theme=light"
          title="Switch to light"
          onClick={event => setMode({ event, mode: 'light' })}
        >
          &#9788;
        </a>
        <span className={dot} />
        <a
          href="?theme=dark"
          className={moon}
          title="Switch to dark"
          onClick={event => setMode({ event, mode: 'dark' })}
        >
          &#9790;
        </a>
      </label>
    </fieldset>
  )
}

export default ThemeSwitcher

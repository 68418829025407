import React from 'react'
import { css } from 'linaria'
import { colors } from '../../utils/theme'
import breakpoints from '../../utils/tokens/breakpoints'
import rhythm from '../../utils/rhythm'
import cx from '../../utils/cx'
import hexToRgb from '../../utils/hexToRgb'

const benefit = css`
  position: relative;
  color: ${colors.text.lightGray};

  h3 {
    margin-top: ${rhythm(1)};
    color: ${colors.text.darkGray};
    position: relative;
    z-index: 2;
  }
`

const number = css`
  &::before {
    content: counter(benefits);
    font-size: 10rem;
    color: #e3eef4;
    position: absolute;
    right: 5%;
    width: 50%;
    text-align: center;
    top: -1rem;
    line-height: 1;
    font-weight: bold;
    counter-increment: benefits;
    background: linear-gradient(
      to bottom,
      ${colors.text.gradientFrom} 0%,
      ${colors.text.gradientTo} 85%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    pointer-events: none;
    z-index: 0;

    @media (min-width: ${breakpoints.md}) {
      font-size: 18rem;
      top: -5rem;
    }
  }
`

const transparent = css`
  @media (prefers-color-scheme: dark) {
    body:not(.light) & {
      &::before {
        background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.2) 0%,
          ${colors.text.gradientTo} 85%
        );
      }
    }
  }

  body.dark & {
    &::before {
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.2) 0%,
        ${colors.text.gradientTo} 85%
      );
    }
  }
`

const accentHeaderC = css`
  h3 {
    color: ${colors.accent.purple};
  }
`

const Benefit = ({
  children,
  className,
  withNumber = false,
  accentHeader = false,
  transparentBackground = false
}) => {
  let classes = cx({
    [benefit]: true,
    [number]: withNumber,
    [accentHeaderC]: accentHeader,
    [transparent]: transparentBackground,
    [className]: Boolean(className)
  })

  return <div className={classes}>{children}</div>
}

export default Benefit

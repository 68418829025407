import React, { useState } from 'react'
import { css } from 'linaria'
import { colors } from '../../utils/theme'
import rhythm from '../../utils/rhythm'
import cx from '../../utils/cx'
import breakpoints from '../../utils/tokens/breakpoints'

const header = css`
  background: ${colors.background.lightGray};
  justify-content: space-between;
  display: flex;
  border-radius: 8px;
  padding: ${rhythm(1)} ${rhythm(1)};
  margin-top: ${rhythm(1 / 2)};
  cursor: cell;
  cursor: zoom-in;
  font-weight: 400;

  @media (min-width: ${breakpoints.md}) {
    font-size: 1.25rem;
    margin-top: ${rhythm(1)};
    padding: ${rhythm(3 / 2)} ${rhythm(3 / 2)};
  }

  p {
    margin: 0;
  }

  &:hover {
    color: ${colors.accent.purple};
  }
`

const icon = css`
  font-size: 1.25rem;
  color: ${colors.accent.purple};
  font-weight: 100;
  line-height: 1;
  margin-left: ${rhythm(1 / 2)};

  @media (min-width: ${breakpoints.md}) {
    font-size: 2.25rem;
  }
`

const headerOpen = css`
  cursor: zoom-out;
`

const content = css`
  background: ${colors.background.lightGray};
  border-radius: 0 0 8px 8px;
  padding: 0 ${rhythm(1)} ${rhythm(1)};
  display: none;
  color: ${colors.text.lightGray};

  @media (min-width: ${breakpoints.md}) {
    font-size: 1.25rem;
    padding: 0 ${rhythm(3 / 2)} ${rhythm(3 / 2)};
  }
`

const contentOpen = css`
  display: block;
`

const Element = ({ question, answer }) => {
  let [isOpen, setOpen] = useState(false)

  let headerClasses = cx({
    [header]: true,
    [headerOpen]: isOpen
  })

  let contentClasses = cx({
    [content]: true,
    [contentOpen]: isOpen
  })

  return (
    <>
      <dt onClick={() => setOpen(!isOpen)} className={headerClasses}>
        <p>{question}</p>
        <span className={icon}>{isOpen ? `-` : `+`}</span>
      </dt>
      <dd className={contentClasses}>{answer}</dd>
    </>
  )
}

const FAQ = ({ elements = [] }) => {
  return (
    elements && (
      <dl>
        {elements.map(item => (
          <Element {...item} />
        ))}
      </dl>
    )
  )
}

export default FAQ

import React, { useEffect } from 'react'
import { Title, Meta } from 'react-head'
import { useStaticQuery, graphql } from 'gatsby'

const bejamasImage = '/img/og/bejamas-main-og.gif'

function useSiteMetadata() {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
            description
            twitter
          }
        }
      }
    `
  )
  return site.siteMetadata
}

const Seo = ({
  pathname,
  pageTitle = null,
  addSuffixToTitle,
  pageDescription,
  pageImage,
  jsonLD
}) => {
  let { title, siteUrl, description, twitter } = useSiteMetadata()
  let documentTitle = pageTitle || title

  let finalTitle = documentTitle

  if (addSuffixToTitle) {
    finalTitle = `${documentTitle} - Bejamas`
  }

  return (
    <>
      <Title>{finalTitle}</Title>
      <Meta charSet="utf-8" />
      <Meta name="description" content={pageDescription || description} />
      <Meta property="og:title" content={documentTitle} />
      <Meta property="og:url" content={siteUrl + pathname} />
      <Meta
        property="og:type"
        content={
          pathname.includes('/blog/') && pathname.length > 6
            ? 'article'
            : 'website'
        }
      />
      <Meta property="og:locale" content="en" />
      <Meta property="og:site_name" content={title} />
      <Meta
        property="og:description"
        content={pageDescription || description}
      />

      {pageImage ? (
        <Meta
          property="og:image"
          content={
            pageImage.startsWith('http') ? pageImage : `${siteUrl}${pageImage}`
          }
        />
      ) : (
        <Meta property="og:image" content={`${siteUrl}${bejamasImage}`} />
      )}

      <Meta property="og:image:width" content="1200" />
      <Meta property="og:image:height" content="630" />

      <Meta name="twitter:card" content="summary_large_image" />
      <Meta name="twitter:site" content={twitter} />
      <Meta name="twitter:creator" content={twitter} />
      <Meta name="twitter:title" content={pageTitle} />
      <Meta name="twitter:domain" content="bejamas.io" />
      <Meta
        name="twitter:description"
        content={pageDescription || description}
      />
      <Meta name="twitter:url" content={siteUrl + pathname} />
      {pageImage ? (
        <Meta
          name="twitter:image"
          content={
            pageImage.startsWith('http') ? pageImage : `${siteUrl}${pageImage}`
          }
        />
      ) : (
        <Meta name="twitter:image" content={`${siteUrl}${bejamasImage}`} />
      )}

      {jsonLD && (
        <Meta
          tag="script"
          type="application/ld+json"
          data-pn={pathname}
          dangerouslySetInnerHTML={{ __html: jsonLD }}
        />
      )}
    </>
  )
}

export default Seo

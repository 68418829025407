import React from 'react'
import Img from 'gatsby-image'
import { css } from 'linaria'
import { useStaticQuery, graphql, Link } from 'gatsby'
import breakpoints from '../../utils/tokens/breakpoints'
import rhythm from '../../utils/rhythm'
import { colors, transition } from '../../utils/theme'

import cx from '../../utils/cx'
import hexToRgb from '../../utils/hexToRgb'

const product = css`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: currentColor;
  transition: transform ${transition.speed.l} ${transition.curve.scaleOut};
  position: relative;

  img {
    border-radius: 8px 8px 0 0;
  }

  p {
    color: ${colors.text.lightGray};
  }

  &::before {
    content: '';
    width: calc(100% + ${rhythm(2)});
    height: calc(100% + ${rhythm(2)});
    background: ${colors.background.bgHover};
    position: absolute;
    left: -${rhythm(1)};
    top: -${rhythm(1)};
    display: block;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    border-radius: 8px;
    transition: all ${transition.speed.l} ${transition.curve.scaleOut};
    box-shadow: 0 9px 60px 0 rgba(${hexToRgb(colors.accent.purple)}, 0.15);
  }

  &:hover {
    transform: translateY(-${rhythm(1 / 8)});

    &::before {
      opacity: 1;
      visibility: visible;
    }

    h3 a {
      color: ${colors.accent.purple};
    }
  }
`

const title = css`
  margin-top: ${rhythm(1)};
`

const link = css`
  margin-top: auto;
  color: ${colors.accent.purple};
  text-decoration: none;
`

const innerContent = css`
  margin-bottom: ${rhythm(3 / 2)};
`

const Product = ({ children, to, className }) => {
  let classes = cx({
    [product]: true,
    [className]: Boolean(className)
  })

  return (
    <Link to={to} className={classes}>
      {children}
    </Link>
  )
}

const Products = ({ jamstackContent, pwaContent, productClassName }) => {
  const data = useStaticQuery(graphql`
    query Products {
      marketingWebsites: file(
        relativePath: { regex: "/img/home/" }
        name: { eq: "product-mw-bg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 445, traceSVG: { color: "#33007a" }) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      pwa: file(
        relativePath: { regex: "/img/home/" }
        name: { eq: "product-pwa-bg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 445, traceSVG: { color: "#ff4062" }) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <>
      <Product to="/services/jamstack-website/" className={productClassName}>
        <Img
          fluid={data.marketingWebsites.childImageSharp.fluid}
          alt="JAMstack websites"
        />
        <h3 className={title}>JAMstack websites</h3>
        <p className={innerContent}>{jamstackContent}</p>
        <span className={link}>See more »</span>
      </Product>
      <Product to="/services/progressive-web-app/" className={productClassName}>
        <Img
          fluid={data.pwa.childImageSharp.fluid}
          alt="Progressive Web Applications"
        />
        <h3 className={title}>Progressive Web Applications</h3>
        <p className={innerContent}>{pwaContent}</p>
        <span className={link}>See more »</span>
      </Product>
    </>
  )
}

export default Products

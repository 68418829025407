import React from 'react'
import { css } from 'linaria'

import { colors } from '../../utils/theme'
import rhythm from '../../utils/rhythm'
import cx from '../../utils/cx'
import breakpoints from '../../utils/tokens/breakpoints'

const section = css`
  padding: ${rhythm(2)} 8vw;
  margin-left: -8vw;
  margin-right: -8vw;

  @media (min-width: ${breakpoints.md}) {
    padding: ${rhythm(3)} 4vw;
    margin-left: 0;
    margin-right: 0;
  }

  @media (min-width: ${breakpoints.xxl}) {
    padding: ${rhythm(3)} ${rhythm(2)};
  }
`

const white = css`
  background: ${colors.background.white};
`

const lightGray = css`
  background: ${colors.background.lightGray};

  @media (min-width: ${breakpoints.md}) {
    border-radius: 16px;
  }
`

const headerClass = css`
  max-width: ${rhythm(28)};
  margin: 0 auto;
  text-align: left;

  @media (min-width: ${breakpoints.md}) {
    text-align: center;
  }
`

const footerClass = css`
  margin-top: ${rhythm(1)};
  text-align: center;

  @media (min-width: ${breakpoints.md}) {
    margin-top: ${rhythm(3)};
  }
`

const headerWide = css`
  max-width: 100%;

  @media (min-width: ${breakpoints.xl}) {
    padding-left: ${rhythm(3)};
    padding-right: ${rhythm(3)};
  }
`

const Section = ({
  variant = 'white',
  className = '',
  headerVariant,
  header = null,
  footer = null,
  footerClassName,
  children,
  ...rest
}) => {
  let classes = cx({
    [section]: true,
    [white]: variant === 'white',
    [lightGray]: variant === 'lightGray',
    [className]: Boolean(className)
  })

  let headerClasses = cx({
    [headerClass]: true,
    [headerWide]: headerVariant === 'wide'
  })

  return (
    <section className={classes} {...rest}>
      {header && <header className={headerClasses}>{header}</header>}
      {children}
      {footer && (
        <footer
          className={cx({
            [footerClass]: true,
            [footerClassName]: Boolean(footerClassName)
          })}
        >
          {footer}
        </footer>
      )}
    </section>
  )
}

export default Section

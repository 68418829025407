// import VerticalRhythm from 'compass-vertical-rhythm'

// const rhythm = VerticalRhythm({
//   baseFontSize: `16px`,
//   baseLineHeight: 1.55
// }).rhythm

// much simpler version, worth considering in the future
const rhythm = number => `${number * 1.55}rem`

export default rhythm

import React from 'react'
import { css } from 'linaria'

import { ReactComponent as Star } from '../../assets/svg/icons/star.svg'
import rhythm from '../../utils/rhythm'
import { colors } from '../../utils/theme'
import breakpoints from '../../utils/tokens/breakpoints'

const starsContainer = css`
  margin-bottom: ${rhythm(1 / 2)};

  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: ${rhythm(3 / 2)};
  }

  svg + svg {
    margin-left: ${rhythm(1 / 3)};
  }
`

const Stars = ({ count }) => {
  let stars = []
  for (let i = 1; i <= count; i++) {
    stars.push(<Star />)
  }

  return <div className={starsContainer}>{stars}</div>
}

const container = css`
  display: flex;
  flex-wrap: wrap;
`

const authorBox = css`
  margin-top: auto;
  display: flex;
  width: 100%;
  position: relative;

  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: ${rhythm(3 / 2)};
  }
`

const avatar = css`
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  background: #000;
  border-radius: 100%;
  margin-right: ${rhythm(1 / 2)};
  margin-bottom: 0;
  flex: 0 0 auto;
`

const contentBox = css`
  color: ${colors.text.lightGray};

  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: ${rhythm(1)};
  }
`

const roleStyle = css`
  @media (min-width: ${breakpoints.lg}) {
    position: absolute;
  }
`

const Testimonial = ({ stars, content, author, role, photo }) => (
  <div className={container}>
    <div className={contentBox}>
      {stars && <Stars count={stars} />}
      <p>{content}</p>
    </div>
    <div className={authorBox}>
      {photo ? (
        <img src={photo} alt={author} className={avatar} />
      ) : (
        <div className={avatar} />
      )}
      <p>
        {author}, <br />
        <span className={roleStyle}>{role}</span>
      </p>
    </div>
  </div>
)

const SmallTestimonials = ({ elements }) => {
  return elements && elements.map(item => <Testimonial {...item} />)
}

export default SmallTestimonials

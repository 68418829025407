import { useEffect } from 'react'

const isBrowser = typeof window !== `undefined`

function getScrollPosition() {
  return { x: window.scrollX, y: window.scrollY }
}

function useScrollPosition(effect, deps = [], wait = 0) {
  let throttleTimeout = null

  const callback = () => {
    effect({ ...getScrollPosition() })
    throttleTimeout = null
  }

  useEffect(() => {
    if (!isBrowser) {
      return
    }

    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callback, wait)
        }
      } else {
        callback()
      }
    }

    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => window.removeEventListener('scroll', handleScroll)
  }, deps)
}

export default useScrollPosition

import React from 'react'
import { css } from 'linaria'
import Button from 'components/Button'
import Input from 'components/Input'

import { Lead, Subheading } from '../TextElements'
import rhythm from '../../utils/rhythm'
import breakpoints from '../../utils/tokens/breakpoints'
import { colors } from '../../utils/theme'

const ctaSection = css`
  padding-bottom: ${rhythm(2)};
  margin-bottom: ${rhythm(2)};
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  @media (min-width: ${breakpoints.md}) {
    text-align: center;
    padding-bottom: ${rhythm(3)};
    margin-bottom: ${rhythm(3)};
  }
`

const form = css`
  margin: ${rhythm(3 / 2)} 0 0;

  @media (max-width: ${breakpoints.md}) {
    a,
    button {
      width: 100%;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    margin: ${rhythm(2)} 0 0;
  }

  input {
    margin-bottom: ${rhythm(1 / 2)};

    @media (min-width: ${breakpoints.md}) {
      margin: 0 ${rhythm(1 / 2)} 0 0;
    }
  }
`

const ctaButton = css`
  margin: ${rhythm(3 / 2)} 0 0;

  @media (min-width: ${breakpoints.md}) {
    margin: ${rhythm(1)} 0 0;
  }
`

const lead = css`
  color: #fff;
  opacity: 0.7;
  max-width: ${rhythm(24)};
  margin-left: auto;
  margin-right: auto;
`

const header = css`
  color: #fff;
`

const CTA = () => (
  <section className={ctaSection}>
    <Subheading variant="purple">JAMstack developers for hire</Subheading>
    <h2 className={header}>Ready to join JAMstack revolution?</h2>
    <Lead className={lead} noMargin>
      Start your super-fast project with us or take your existing product to the
      next level.
    </Lead>
    <Button to="/estimate-project/" className={ctaButton}>
      Get started for Free
    </Button>
  </section>
)

export default CTA

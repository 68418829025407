import React from 'react'
import { css } from 'linaria'
import NonStretchedImg from 'components/NonStretchedImage'

import rhythm from '../../utils/rhythm'
import { colors, fonts } from '../../utils/theme'
import breakpoints from '../../utils/tokens/breakpoints'
import cx from '../../utils/cx'

const hero = css`
  position: relative;
  padding-top: ${rhythm(1)};

  @media (min-width: ${breakpoints.md}) {
    padding-bottom: 2rem;
    display: grid;
    grid-template-columns: 60% auto;
    align-items: center;
    padding-top: 0;
    margin-right: -4vw;
  }

  @media (min-width: ${breakpoints.lg}) {
    margin-right: 0;
  }

  @media (min-width: ${breakpoints.xl}) {
    height: calc(100vh - 100px);
    min-height: 40rem;
    max-height: 44rem;
    grid-template-columns: 54% auto;
  }

  @media (min-width: ${breakpoints.xxl}) {
    grid-template-columns: 52% auto;
    margin-right: 0;
  }
`

const heroContent = css`
  p:last-of-type {
    margin-bottom: ${rhythm(3 / 2)};
  }

  @media (min-width: ${breakpoints.md}) {
    padding-right: ${rhythm(1)};
  }

  @media (min-width: 1200px) {
    padding-left: ${rhythm(2)};
    padding-right: ${rhythm(2)};
  }
`

const imageWrapper = css`
  padding-bottom: ${rhythm(2)};

  @media (max-width: ${breakpoints.sm}) {
    display: none !important;
  }

  @media (min-width: ${breakpoints.md}) {
    margin-right: -60%;
  }

  @media (min-width: ${breakpoints.lg}) {
    margin-right: -40%;
  }

  @media (min-width: ${breakpoints.xl}) {
    margin-right: 0;
  }

  @media (min-width: ${breakpoints.xxl}) {
    margin-right: -${rhythm(2)};
  }
`

const Hero = ({ image, content, particles, className, imageClassName }) => {
  let sectionClasses = cx({
    [hero]: true,
    [className]: Boolean(className)
  })

  let imageClasses = cx({
    [imageWrapper]: true,
    [imageClassName]: Boolean(imageClassName)
  })

  return (
    <section className={sectionClasses}>
      <div className={heroContent}>{content}</div>
      <div className={imageClasses}>
        <NonStretchedImg
          fluid={image.childImageSharp.fluid}
          alt="Bejamas Hero"
          loading="lazy"
        />
      </div>
      {particles}
    </section>
  )
}

export default Hero

import React from 'react'
import { css } from 'linaria'
import { colors, fonts } from '../../../utils/theme'
import rhythm from '../../../utils/rhythm'
import cx from '../../../utils/cx'
import breakpoints from '../../../utils/tokens/breakpoints'

const subheading = css`
  text-transform: uppercase;
  letter-spacing: 0.125rem;
  font-family: ${fonts.body};
  font-size: 0.625rem;
  font-weight: bold;
  margin-bottom: ${rhythm(1)};
  color: ${colors.text.lightGray};

  @media (min-width: ${breakpoints.md}) {
    font-size: 0.875rem;
    margin-bottom: ${rhythm(3 / 2)};
    letter-spacing: 0.1875rem;
  }
`

const purple = css`
  color: ${colors.accent.purple};
`

const orange = css`
  color: ${colors.accent.orange};
`

const Subheading = ({ children, tag: Tag = 'h3', variant, className }) => {
  let classes = cx({
    [subheading]: true,
    [orange]: variant === 'orange',
    [purple]: variant === 'purple',
    [className]: Boolean(className)
  })

  return <Tag className={classes}>{children}</Tag>
}

export default Subheading

import React from 'react'
import { css } from 'linaria'
import cx from '../../utils/cx'

import { ReactComponent as Facebook } from 'assets/svg/icons/facebook.svg'
import { ReactComponent as Twitter } from 'assets/svg/icons/twitter.svg'
import { ReactComponent as LinkedIn } from 'assets/svg/icons/linkedin.svg'
import { ReactComponent as Github } from 'assets/svg/icons/github.svg'

import breakpoints from '../../utils/tokens/breakpoints'
import rhythm from '../../utils/rhythm'

const social = css`
  display: flex;

  li + li {
    margin-left: ${rhythm(1)};
  }

  @media (max-width: ${breakpoints.md}) {
    grid-column: 1 / 2;
  }

  @media (min-width: ${breakpoints.lg}) {
    justify-content: flex-end;
  }
`

const SocialMedia = ({ className }) => {
  let classes = cx({
    [social]: true,
    [className]: Boolean(className)
  })

  return (
    <ul className={classes}>
      <li>
        <a
          href="https://www.facebook.com/bejamas.io"
          target="_blank"
          title="Facebook"
          rel="noopener noreferrer"
        >
          <Facebook />
        </a>
      </li>
      <li>
        <a
          href="https://twitter.com/bejamas_io"
          target="_blank"
          title="Twitter"
          rel="noopener noreferrer"
        >
          <Twitter />
        </a>
      </li>
      <li>
        <a
          href="https://www.linkedin.com/company/bejamas/"
          target="_blank"
          title="LinkedIn"
          rel="noopener noreferrer"
        >
          <LinkedIn />
        </a>
      </li>
      <li>
        <a
          href="https://github.com/bejamas"
          target="_blank"
          title="Github"
          rel="noopener noreferrer"
        >
          <Github />
        </a>
      </li>
    </ul>
  )
}

export default SocialMedia
